body{margin:0px!important; padding:0px; font-family: 'Roboto', sans-serif!important;
 font-size:16px!important;background-color:#e6eaf3 !important;font-weight:normal!important;

 
 }

a{text-decoration:none;color: #000;}
p{padding:0px 0px 10px 0px; margin:0px; text-align:justify;color:#6f6f6f}
h1,h2,h3,h4,h6{padding:0px; margin:0px 0px 10px 0px; }
h1{font-size:25px}
h2{font-size:23px}
h3{font-size:21px}
h4{font-size:18px}
.clear{clear:both;}
.iconvertical{vertical-align:middle}
.aligncentr{text-align:center}
.content{margin:15px 0}
.alignright{text-align:right}
.floatright{float:right}
.main{width:94%;margin:auto}
.searchbtn{
margin-left:1%;	
margin-top:29px;
}
.MuiButton-root{
color:#fff;
}
/* Login page */

.logincontents ul{
	margin:0px;
	padding:0px;
}

.logincontents li{
	margin:10px 0px;list-style:none;
}
.validationtxt span{color:red;}

.actionbtns ul{
	margin:0px;
	padding:0px;}

.actionbtns li{
	list-style:none;
	display:inline-block;
}
.delbtn{
	
	background-color:#909090!important;
	color:white;
	  border-radius: 2px;
	}
.adminmenu li svg{
color:#9da6ae;
font-size: 20px !important;
}	
.editbtn{
	background-color:#a97d34;
	color:white;
	  border-radius: 2px;
	}
.validationtxt{color:red;}	
.validation{color:red;}	
.vendrtag{
	margin-top:10px;
}
.packtag{
	margin-top:20px;
}
.searchnewbtn ul,.searchmsters ul{margin:0px;
	padding:0px;}
.searchnewbtn li{
	list-style:none;
	display:inline-block;
	
    text-align: center;
	    margin-left: 5%;
	}	
.searchbutton{
	background-color:#a97d34;
	color:white;
	  border-radius: 2px;
	  }
	  .searchbttn{
		  background-color:#a97d34 !important;
	color:white !important;
	  border-radius: 2px;
	  }
.addnewbtn{background-color:#9660ff;
	color:white;
	  border-radius: 2px;padding:0px 15px;}	 
.searchpart ul{margin:0px;
	padding:0px;}
.expbtn{background-color:#9660ff;
	color:white;
	  border-radius: 3px;padding:10px 15px; border:none;cursor: pointer;}	
	  .printreport{
		    background-color: #909090;
    color: white;
    border-radius: 3px;
    padding: 8px 15px;
    display: inline-block;
    cursor: pointer;
}  
.searchmasters ul{
padding:0px;
margin:0px;
}	
.searchmasters li{
list-style:none;
	display:inline-block;
	 padding: 10px 5px;
    vertical-align: middle;
	
}	
.searchpart li{
	list-style:none;
	display:inline-block;
	    width: 40%;
		margin-left:5%;
	}  
	.searchbtton{
	margin-top: 0px;
}
.stadrp{width:100%;margin-top:4px !important;}
.searchmaster ul{margin:0px;
	padding:0px;}
.searchmaster li{list-style:none;
	display:inline-block;
	vertical-align:middle;
	    }	
		
.searchmsters li{
list-style:none;
	display:inline-block;
	 padding: 10px 5px;
    vertical-align: middle;
	width:29%;
}	
.searchnewbtn{float:right;width:50%;margin-top:15px;}

.searchpart{float:left;width:50%;}

.css-1dmqq7i-MuiNativeSelect-select-MuiInputBase-input-MuiInput-input.css-1dmqq7i-MuiNativeSelect-select-MuiInputBase-input-MuiInput-input.css-1dmqq7i-MuiNativeSelect-select-MuiInputBase-input-MuiInput-input {
    padding-right: 24px;
    min-width: 16px;
    margin-top: 10px;
}

.adminpanel{display:table;width:100%}
.innerleft-col{background-color:#fff;width:15%;display:table-cell;vertical-align:top;height:750px}
.contentsecinner-col{width:75%;display:table-cell;vertical-align:top;background-color:#f3f4f6;}
.toploginoptiondiv{
    padding-top: 10px;
    background-color: #fff;
    /*border-left: 1px solid #cdced2;*/float:right;
}	
.toploginoptiondiv ul{margin:0px;padding:0px;text-align:right}
.toploginoptiondiv li{display:inline-block;vertical-align:middle;margin:0px 2%}
.toploginoptiondiv li p{padding:0px;font-size:14px;color:#9c9e9f}
.iconvertical{vertical-align:middle}
.adminmenu ul{margin:10px 0px 0 20px;padding:0px;}
.adminmenu li{display:inline-block; position: relative; 
  border-radius: 10px 0 0 10px;vertical-align:middle;margin:8px 0;padding:10px 1% 10px 4%;width:95%;color:#fff}

.adminmenu li.active{background-color:#f3f4f6;border-top-left-radius: 10px;border-bottom-left-radius: 10px;color:#000}
.adminmenu li.active a,.adminmenu li:hover a{color:#000}
.adminmenu li .iconvertical{margin-right:5px;font-size: 30px;}
.mastheadleft {
    float: left;
    width: 30%;
}
.welcometext {
    padding: 0px 0px 0px 0px ! important;
    margin-right: 33%;
}
.mainmenus .MuiListItem-padding{
	padding-left:9px;
	padding-right:0px;
}
 .MuiPopover-paper{
	left:800px;
}
.masthead {
    padding: 10px 10px;
    margin-top: -40px;
    background-color: #ffffff;
    border-radius: 15px;
    margin-left: 1%;
    margin-right: 1%;
}
.mastheadright {
    float: right;
    margin: 20px 5% 0px 0%;
}

.inputrating {
    display: inline-block;
    vertical-align: middle;
}
.feedbackleft{
	float:none;
	width:100%;
	margin-right:0%;
	}
	
	.contentmain{
		border-left:10px solid transparent;
	border-right:10px solid transparent;
	border-bottom:10px solid transparent;
	}
	.containermain .css-rorn0c-MuiTableContainer-root,.containermain .css-1ps6pg7-MuiPaper-root{
		border-radius:20px;
	}
	 .contentmain form{
    width: 90%;
    margin: 10px auto;
    background: #fff;
    padding: 10px 20px;
    border-radius: 20px;
	
}
.headers h1{
	margin-left:4%;
	font-size:30px;
	font-weight:500;
}
.containerbg{
background-color: #fff;
    border-radius: 20px;
    padding: 20px;
}
.containerbg .css-1ps6pg7-MuiPaper-root{
	box-shadow:none;
}
.feedbackcollft{
float:left;
width:39%;	
}
.feedbackcollft p{
	text-align:right !important;
}
.feedbackcollrt{
float:left;
width:57%;	
margin-left:3%;
}
.header{
	text-align:center;
}
.header h2{
	    border-bottom: 1px solid #936b1f;
    display: inline-block;
    color: #936b1f;
}
.feedbackcollft p{
	text-align:left;
}
.contentmain p{
	text-align:left;
}
.MuiOutlinedInput-input {
    padding: 8px 10px !important;
}
.MuiInputBase-inputMultiline {
    height: auto !important;
    
}
.css-hlj6pa-MuiDialogActions-root{
	display:block !important;
}
.savebtns .savebttn
{
	float:right;	
	text-align:right;
	background-color:#a97d34;
	color:white;
	border-radius: 2px;
	
}
.savebtns .savebttn:hover{
background-color:#a97d34;
	color:white;	
}


.backbutton {
    text-align: right;
}
.actionbtns{
width:100%;
}
.delbtns .delbttn{
	float:left !important;
	
	text-align:left;
	background-color:#909090!important;
	color:white;
	  border-radius: 2px;
}
.thankyou{
	text-align:center;
	padding:80px 0px;
}
.mastheadleft img{
	width:100px;
	
}
.innerlogo{
padding:10px 0px 10px;
border-bottom:1px solid #eee;
text-align:center;
float:left;
width:20%;
}
.frmPrimarybtn{
	background-color:#a97d34 !important;
	color:#fff !important;
	border-radius: 25px !important;
    padding: 5px 20px !important;

}
.contentseclog-cols{
	padding-bottom:30px;
}
.btnsubmits{
	display:inline-block;
}
.logindiv{
	width:400px;
margin:auto;	
	padding:80px 20px 20px 20px;
	border-radius:20px;
	background-color:#fff;
	color:#cf872a;
	text-align:center;
}
.mainscreen-rows{background-image:url(./images/login-screen-bg.jpg);background-repeat:no-repeat;background-size:100% 100%;background-position:top center;
	background-color: #cf872a;padding-bottom:120px
}
.loglogo{
	width: 30%;
    margin: 20px auto -65px;
    text-align: center;
}
.loglogo img{
	width:150px !important;
}
.leftbandlog-cols{
	padding:25px 0px 0px;
	text-align:center;
}
.reportclass td{
	border:1px ;
}
.stepperheader{
margin:10px 0px 20px;
}
stdrp{
	width:100%;
}
.stepperheader .Mui-active,.stepperheader .Mui-completed {
    color: #936b1f !important;
}
.feedbackmain{
	width:50%;
	margin:auto;
}
#vendorreport .css-q34dxg{
	border-bottom:0px !important;
}
.menulist li {
    list-style: none;
    padding: 10px 2% 10px 3%;
}
.repfromdetails{
	float:left;
	
}
.reportrt{
float:right;	
}
@media(max-width:650px) {
	.feedbackmain{
	width:100%;
	margin:auto;
}
	.feedbackright {
    float: none;
    width: 100%;
}
.feedbackleft {
    float: none;
    width: 100%;
    margin-right: 0%;
}
.logindiv{
width:80%;}
.searchpart {
    float: none;
    width: 100%;
}
.searchnewbtn {
    float: none;
    width: 100%;
    margin-top: 5px;
}
.css-m5vj9m-MuiStepper-root {
display:block;
}
.css-1bw0nnu-MuiStep-root {
    padding-left: 8px;
    padding-right: 8px;
    display: inline-block !important;
}
.css-ascpo7-MuiStepLabel-root {    
    display: block !important;   
    align-items: center !important;
}
.css-vnkopk-MuiStepLabel-iconContainer {
   
    display: block !important;
    padding-right: 0px;
}
.css-1u4zpwo-MuiSvgIcon-root-MuiStepIcon-root{
margin:auto;
}
.searchmsters li,.searchmasters li{   
	width:100%;
}
.searchpart li {
    
    width: 100%;
    margin-left: 2%;
}
.searchnewbtn li {
   
    
    margin-left: 2%;
}
}